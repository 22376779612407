import React, {useEffect, useState, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchModuleSectionList,
  fetchSectionObjectsList,
  addSectionList,
  updateSectionList,
  updateSectionStatus,
} from '../../../store/actions/sectionActions.js';
import CustomTable from '../../../components/CustomTable/CustomTable.js';
import {PiDotsThreeVertical} from 'react-icons/pi';
import DropdownMenu from '../../../components/DropdownMenu/DropdownMenu.js';
import {useIntl} from 'react-intl';
import DeactivateModal from '../../../components/DeactivateModal/DeactivateModal.js';
import {useNavigate, useParams} from 'react-router-dom';
import {
  addSectionFieldName,
  addSectionInitialValues,
  validationSchema,
} from '../../../validationSchema/AddSectionSchema.js';
import {useFormik} from 'formik';
import {
  editContentButtonsAvailable,
  getIslandStatusText,
  getLocalizedMessage,
} from '../../../utilityFunction/helper.js';
import {MANAGE_LESSONS_PATH} from '../../../constants/routePaths.js';
import AddSection from './AddSection.js';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import {Typography} from '@mui/material';
import './SectionListingStyle.scss';
import moment from 'moment';
import {showToast} from '../../../components/Toast/Toast.js';
import useUserRole from '../../../hooks/useUserRole.js';
import {updateModuleName} from '../../../store/actions/sectionActions.js';

function SectionListing() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {isAuthenticated} = useSelector(state => state?.auth);
  const sectionsList = useSelector(state => state.section.sectionsList?.data);
  const sectionObjectsList = useSelector(
    state => state.section.sectionObjectsList,
  );
  const [selectedSection, setSelectedSection] = useState(null);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [openAddSectionDialog, setOpenAddSectionDialog] = useState(false);
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [selectedSectionData, setSelectedSectionData] = useState(null);
  const {id} = useParams();
  const [editMode, setEditMode] = useState(false);
  const [sectionId, setSectionId] = useState(null);
  const userRole = useUserRole();
  const isEditAvailable = editContentButtonsAvailable(userRole);

  useEffect(() => {
    fetchSectionData();
  }, []);

  useEffect(() => {
    dispatch(fetchSectionObjectsList({id}));
    fetchSectionData();
  }, [id]);

  const fetchSectionData = () => {
    dispatch(fetchModuleSectionList({id, loaderDispatch}));
  };

  const handleAvatarClick = section => {
    if (selectedSection?.id === section?.id) setSelectedSection(null);
    else setSelectedSection(section);
  };

  const editDetails = (id, sectionId) => {
    setEditMode(true);
    const selectedSectionData = sectionsList?.data.find(
      section => section.id === sectionId,
    );
    setSectionId(sectionId);
    if (selectedSectionData) {
      setSelectedSectionData(selectedSectionData);
      setOpenAddSectionDialog(true);
      dispatch(fetchSectionObjectsList({id, sectionId}));
    } else {
      console.error(`Section with ID ${sectionId} not found in sectionsList`);
    }
  };

  const handleDisableConfirm = async () => {
    if (sectionObjectsList?.length === 0 && !selectedSection?.is_active) {
      showToast(getLocalizedMessage(intl, 'label.no-objects-left'), 'warning');
    } else if (selectedSection?.id) {
      setOpenDisableModal(false);
      dispatch(updateSectionStatus({id: selectedSection?.id, intl})).then(
        () => {
          setSelectedSection(null);
          dispatch(fetchSectionObjectsList({id})).then(() => {
            fetchSectionData();
          });
        },
      );
    }
  };

  const TABLE_HEAD = [
    {
      id: 'id',
      label: intl.formatMessage({id: 'label.s_no'}),
      alignRight: false,
      minWidth: '5%',
      renderColumn: (section, index) => {
        const calculatedIndex = index + 1;
        return <Typography variant="subtitle">{calculatedIndex}</Typography>;
      },
    },
    {
      id: 'name',
      label: intl.formatMessage({id: 'label.sections_name'}),
      alignRight: false,
      dataKey: '',
      renderColumn: section => {
        return (
          <div
            key={section.id}
            className="clickable-hover"
            onClick={() => seeLessonsHandler(section)}>
            <div className="s_name_address d-flex">
              <div>
                <div className="s_name">{section.name}</div>
                <div className="s_address">
                  No. of Modules: {section.no_of_lessons}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'is_active',
      label: intl.formatMessage({id: 'label.status'}),
      alignRight: false,
      dataKey: 'is_active',
      renderColumn: section => {
        return (
          <div className="d-flex actions-wrapper">
            <div
              className={`${
                section.is_active ? 'school-active' : 'school-deactivate'
              }`}>
              {section.is_active ? 'Active' : 'Inactive'}
            </div>
          </div>
        );
      },
    },
    {
      id: 'module_element',
      label: intl.formatMessage({id: 'label.objects'}),
      alignRight: false,
      dataKey: 'module_element',
      renderColumn: section => {
        return (
          <div className="d-flex actions-wrapper">
            <div>{section.module_element.name}</div>
          </div>
        );
      },
    },
    {
      id: 'created_at',
      label: intl.formatMessage({id: 'label.created'}),
      alignRight: false,
      dataKey: 'created_at',
      renderColumn: section => {
        return <div>{moment(section.created_at).format('DD/MM/YYYY')}</div>;
      },
    },
    {
      id: 'updated_at',
      label: intl.formatMessage({id: 'label.last_edited'}),
      alignRight: false,
      dataKey: 'updated_at',
      renderColumn: section => {
        return <div>{moment(section.updated_at).format('DD/MM/YYYY')}</div>;
      },
    },
    {
      id: 'quickActions',
      label: '',
      alignRight: false,
      dataKey: '',
      renderColumn: section => {
        return (
          <div className="d-flex actions-wrapper">
            <div className="actions gap-1">
              <img src="/images/preview.svg" alt="preview" />
            </div>
            {isEditAvailable && (
              <div
                className={
                  section?.id === selectedSection?.id
                    ? 'edit-delete selected-action'
                    : 'edit-delete'
                }>
                <div style={{position: 'relative'}}>
                  <PiDotsThreeVertical
                    onClick={() => handleAvatarClick(section)}
                  />
                  {section?.id === selectedSection?.id && (
                    <DropdownMenu isOpen={true}>
                      <div
                        className="pointer"
                        onClick={() => editDetails(id, section.id)}>
                        {intl.formatMessage({id: 'label.edit_details'})}
                      </div>

                      <div
                        className="pointer"
                        onClick={() => setOpenDisableModal(true)}>
                        {getIslandStatusText(section?.is_active, intl)}
                      </div>
                    </DropdownMenu>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const handleAddSection = id => {
    setSelectedSectionId(id);
    setEditMode(false);
    setFieldValue(addSectionFieldName.NAME, '');
    setFieldValue(addSectionFieldName.TOKEN, '');
    setOpenAddSectionDialog(true);
    dispatch(fetchSectionObjectsList({id})).then(() => {
      fetchSectionData();
    });
  };

  const handleCloseAddSectionDialog = () => {
    setOpenAddSectionDialog(false);
    setSelectedSectionId(null);
    resetForm();
  };

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: addSectionInitialValues,
    onSubmit: values => {
      if (editMode) {
        if (values.module_element_name) {
          dispatch(
            updateModuleName({
              id: selectedSectionData.module_element.id,
              payload: {
                module_id: selectedSectionData.module_element.module_id,
                name: values.module_element_name,
              },
              intl,
            }),
          ).then(() => {
            dispatch(
              updateSectionList({
                id: sectionId,
                payload: {
                  ...values,
                  token_credits: parseInt(values.token_credits),
                  module_element_id: parseInt(values.module_element),
                  module_id: parseInt(id),
                },
                intl,
              }),
            ).then(() => {
              dispatch(fetchSectionObjectsList({id}));
              fetchSectionData();
            });
          });
        }
        // dispatch(
        //   updateSectionList({
        //     id: sectionId,
        //     payload: {
        //       ...values,
        //       token_credits: parseInt(values.token_credits),
        //       module_element_id: parseInt(values.module_element),
        //       module_id: parseInt(id),
        //     },
        //     intl,
        //   }),
        // ).then(() => {
        //   dispatch(fetchSectionObjectsList({id}));
        //   fetchSectionData();
        // });
      } else {
        dispatch(
          addSectionList({
            ...values,
            token_credits: parseInt(values.token_credits),
            module_element_id: parseInt(values.module_element),
            module_id: parseInt(id),
          }),
        ).then(() => {
          dispatch(fetchSectionObjectsList({id}));
          fetchSectionData();
        });
      }
      handleCloseAddSectionDialog();
      setOpen(false);
    },
    validationSchema: validationSchema(intl),
  });

  const seeLessonsHandler = section => {
    navigate(MANAGE_LESSONS_PATH, {
      state: {
        sectionName: section?.name,
        sectionId: section?.id,
      },
    });
  };

  const getSectionDisabledCount = () => {
    return sectionObjectsList?.length === 0;
  };

  return (
    <div>
      <div className="d-flex student-detail-wrapper">
        <div className="school-label">
          {intl.formatMessage({id: 'label-all-section-details'})}
        </div>
        <div>
          {isEditAvailable && (
            <button
              className="btn btn-primary"
              disabled={getSectionDisabledCount()}
              onClick={() => handleAddSection(id)}>
              <img src={'/images/white-add.svg'} alt={'Edit lesson'} />
              <span className="ms-2">
                {intl.formatMessage({id: 'label.add_new_section'})}
              </span>
            </button>
          )}
        </div>
      </div>

      <CustomTable
        tableHead={TABLE_HEAD}
        tableData={sectionsList?.data}
        showPagination={false}
      />
      {openDisableModal && (
        <DeactivateModal
          open={openDisableModal}
          setOpen={setOpenDisableModal}
          handleDeactivate={handleDisableConfirm}
          confirmText={getIslandStatusText(selectedSection?.is_active, intl)}
          modalContentText={
            !selectedSection?.is_active
              ? getLocalizedMessage(intl, 'label.enableSection')
              : getLocalizedMessage(intl, 'label.disableSection')
          }
        />
      )}

      {openAddSectionDialog && (
        <AddSection
          open={openAddSectionDialog}
          handleClose={handleCloseAddSectionDialog}
          handleSubmit={handleSubmit}
          values={values}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          sectionData={selectedSectionData}
          editMode={editMode}
        />
      )}
    </div>
  );
}

export default SectionListing;
